<template>
	<div>
		<div class="d-flex align-center justify-center" style="height: 80px; border-top: 1px solid #e7eaf3; position: relative">
			<v-row no-gutters class="px-6 d-flex flex-row justify-space-between align-center">
				<div class="chat-message-input">
					<v-textarea
						rows="1"
						id="message"
						auto-grow
						color="#CACEDA"
						hide-details
						v-model="message"
						append-icon="face-happy-outline"
						placeholder="Write a reply"
					>
						<div slot="append" style="cursor: pointer" @click="toogleDialogEmoji">
							<icon-smiley style="color: #6c7c95; width: 20px" />
						</div>
					</v-textarea>
				</div>
				<div class="document-upload d-flex cursor-pointer">
					<v-file-input @change="previewDocument" accept="application/pdf" id="fileUpload" multiple class="d-none"> </v-file-input>
					<icon-file-input style="color: #6c7c95; width: 20px" @click.native="openFileInput" />
				</div>
				<div class="photo-upload d-flex cursor-pointer">
					<v-file-input
						@change="previewDocument"
						accept="image/png, image/jpeg, image/bmp, image/gif"
						multiple
						id="imageUpload"
						class="d-none"
					></v-file-input>
					<icon-gallery style="color: #6c7c95; width: 20px" @click.native="openImageInput" />
				</div>
				<div class="d-flex cursor-pointer">
					<icon-send style="color: #6c7c95" @click.native="textmessage" />
				</div>
			</v-row>
			<VEmojiPicker class="emoji-keyboard" v-show="showEmojiPicker" @select="selectEmoji" />
			<div v-if="loading" class="d-flex align-center justify-center message-loader">
				<icon-loader />
			</div>
		</div>

		<div class="custom-error" style="padding-left: 30px; margin-top: -10px">{{ errorMsg }}</div>
	</div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'
import firebase from 'firebase/app'
import { AXIOS } from '../plugins/axios'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../config/variable'
const iconSmiley = () => import('../mycomponents/icon-smiley.vue')
const iconFileInput = () => import('../mycomponents/icon-file-input.vue')
const iconGallery = () => import('../mycomponents/icon-gallery.vue')
const iconSend = () => import('../mycomponents/icon-send.vue')
const iconLoader = () => import('../mycomponents/icon/icon-loader.vue')
export default {
	data() {
		return {
			userId: this.$store.state.auth.user_data.company_id,
			message: '',
			showEmojiPicker: false,
			imageData: null,
			fileType: ['image/jpeg', 'image/bmp', 'image/gif', 'image/png', 'application/pdf'],
			errorMsg: '',
			docType: 'text',
			loading: false,
		}
	},

	components: {
		VEmojiPicker,
		iconSmiley,
		iconFileInput,
		iconGallery,
		iconSend,
		iconLoader,
	},

	mounted() {
		const vm = this
		const ele = document.querySelector('#message')
		ele.addEventListener('keydown', function (e) {
			const keyCode = e.which || e.keyCode

			if (keyCode === 13 && !e.shiftKey) {
				vm.textmessage()
				e.preventDefault()
			}
		})
	},

	methods: {
		toogleDialogEmoji() {
			this.showEmojiPicker = !this.showEmojiPicker
		},

		selectEmoji(emoji) {
			this.message = this.message ? (this.message += emoji.data) : emoji.data
			this.showEmojiPicker = !this.showEmojiPicker
			document.getElementById('message').focus()
		},

		openFileInput() {
			document.getElementById('fileUpload').click()
			this.docType = 'file'
		},

		openImageInput() {
			document.getElementById('imageUpload').click()
			this.docType = 'image'
		},

		previewDocument(event) {
			this.imageData = event
			this.onUpload(event)
		},

		async onUpload(imageFile) {
			imageFile.forEach((item) => {
				if (this.fileType.includes(item.type)) {
					this.loading = true
					const self = this

					var storageRef = firebase.storage().ref(item.name).put(item)

					storageRef.on(
						'state_changed',
						function progress(snapshot) {
							var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
							self.progressUpload = percentage
						},
						() => {
							// console.log('error')
							this.loading = false
						},
						async () => {
							const res = await storageRef.snapshot.ref.getDownloadURL()
							this.createMessage({ docUrl: res, docName: item.name, docSize: item.size })
						}
					)
				} else {
					this.errorMsg = `This ${this.docType.toLowerCase()} type is not supported`
				}
			})
		},

		textmessage() {
			this.docType = 'text'
			if (this.message && this.message.trim().length > 0) {
				this.createMessage()
			}
		},

		async createMessage(data1) {
			// Create a new message

			await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
				.doc(this.$store.state.chathaggle.collection_id)
				.collection('chats')
				.add({
					image_url: '',
					chatRoomId: this.$store.state.chathaggle.collection_id,
					message: this.docType.toLowerCase() == 'text' ? this.message : data1.docName,
					time: Date.now(),
					sendBy: `${this.userId}DC`,
					toSend: `${this.$store.state.chathaggle.contractor_id}C`,
					type: 0,
					docType: this.docType.toLowerCase(),
					acceptRejectLable: '',
					bidobj: null,
					deleteBy: null,
					docUrl: this.docType.toLowerCase() == 'text' ? '' : data1.docUrl,
					docName: this.docType.toLowerCase() == 'text' ? '' : data1.docName,
					docSize: this.docType.toLowerCase() == 'text' ? '' : `${Math.round((data1.docSize / 1024 / 1024) * 100) / 100} MB`,
				})

			const fireBaseC = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc(`${this.$store.state.chathaggle.contractor_id}C`)
				.get()
			const cData = fireBaseC.data()

			const fireBaseDC = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc(`${this.$store.state.auth.user_data.company_id}DC`)
				.get()
			const dcData = fireBaseDC.data()

			const data2 = JSON.stringify({
				notification: {
					title: this.$store.state.auth.user_data.company.name,
					body: this.message,
					badge: '1',
					sound: 'default',
					click_action: 'FLUTTER_NOTIFICATION_CLICK',
				},
				registration_ids: cData.fcm_id,
				content_available: true,
				priority: 'high',
				data: {
					profile_url: dcData.profile_link,
					distributon_company_name: dcData.fullname,
					distributon_company_id: dcData.id.toString(),
					address: dcData.address,
					chatRoomId: this.$store.state.chathaggle.collection_id.toString(),
				},
			})

			AXIOS.post('distributor/chat-notification-to-contractor', data2)
				.then(() => {
					// console.log(response, 'responseeee')
				})
				.catch(() => {})

			this.message = null
			this.showEmojiPicker = false
			this.errorMsg = ''
			this.imageData = null
			document.getElementById('imageUpload').value = null
			document.getElementById('fileUpload').value = null
			this.loading = false
		},
	},
}
</script>

<style lang="scss">
.chat-message-input {
	width: calc(100% - 100px);

	.v-input {
		caret-color: rgb(108, 110, 116) !important;
	}
}
.chat-message-input .v-input__slot {
	background: #e0e8ed !important;
	border: none !important;
	border-radius: 50px !important;
	padding-left: 15px;
	min-height: 40px !important;
}
.chat-message-input .v-input__control > .v-input__slot:focus-within {
	background: #e0e8ed !important;
}
.chat-message-input .v-text-field > .v-input__control > .v-input__slot:before,
.chat-message-input .v-text-field > .v-input__control > .v-input__slot:after {
	border: none !important;
}
.chat-message-input .v-text-field > .v-input__control > .v-input__slot:hover:before {
	border: none !important;
}

.chat-message-input .v-input__control > .v-input__slot {
	box-shadow: none !important;
}
.chat-message-input .v-input__control > .v-input__slot > .v-text-field__slot > textarea {
	max-height: 60px;
	line-height: 2rem;
}
.chat-message-input .v-text-field > .v-input__control > .v-input__slot:before,
.chat-message-input .v-text-field > .v-input__control > .v-input__slot:after {
	bottom: 0px !important;
}
.chat-message-input .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
	padding-left: 10px !important;
}
.chat-message-input .v-text-field .v-input__append-inner,
.v-text-field .v-input__prepend-inner {
	margin-top: 8px !important;
	margin-right: 10px !important;
}
.chat-message-input .v-input {
	margin: 0px !important;
	padding: 0px !important;
}

.emoji-keyboard {
	position: absolute;
	height: 300px;
	bottom: 60px;
	left: 40px;
}

@media only screen and (max-width: 400px) {
	.emoji-keyboard {
		left: 0;
	}
}
// .v-overlay--active {
// 	.v-overlay__scrim {
// 		background-color: rgb(41 42 42 / 46%) !important;
// 		opacity: 0.6 !important;
// 	}
// }

.message-loader {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	background: #e3e3e369;
	height: 100%;
}
</style>
