import { render, staticRenderFns } from "./CreateChatMessage.vue?vue&type=template&id=9c8df88a&"
import script from "./CreateChatMessage.vue?vue&type=script&lang=js&"
export * from "./CreateChatMessage.vue?vue&type=script&lang=js&"
import style0 from "./CreateChatMessage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VFileInput,VRow,VTextarea})
